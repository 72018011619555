import { createRouter, createWebHistory } from 'vue-router'
import Auth from '../views/Auth.vue'
import Perfil from "../views/Perfil.vue"
import Edit from "../views/Edit.vue"
import { auth } from "../middleware/auth";

const routes = [{
        path: '/',
        name: 'Auth',
        component: Auth,
        beforeEnter: auth.userLogin,
    }, {
        path: '/perfil',
        name: 'Perfil',
        component: Perfil,
        beforeEnter: auth.userPerfil,
    }, {
        path: '/perfil/edit/nickname/:name',
        name: 'Edit',
        component: Edit,
        beforeEnter: auth.userPerfil,
    },
    { path: "/:pathMatch(.*)*", redirect: "/" },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router