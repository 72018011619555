import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
export const auth = {

    userLogin(to, from, next) {
        const existCookie = cookies.isKey('sessionUser');
        if (existCookie) {
            const cookieAdmin = cookies.get('sessionUser');
            if (cookieAdmin === null) {
                cookies.remove('sessionUser');
                next();
            } else {
                next({ name: 'Perfil' });
            }
        } else {
            next();
        }
    },
    userPerfil(to, from, next) {
        const existCookie = cookies.isKey('sessionUser');
        if (existCookie) {
            const cookieAdmin = cookies.get('sessionUser');
            if (cookieAdmin === null) {
                cookies.remove('sessionUser');
                next({ name: 'Auth' });
            } else {
                next();
            }
        } else {
            next({ name: 'Auth' });
        }

    },
}