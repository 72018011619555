import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./style/tailwind.css";
import GAuth from "vue3-google-oauth2";
import VueCookies from 'vue3-cookies'
import vue3GoogleLogin from 'vue3-google-login'

const app = createApp(App);
app.use(VueCookies).use(vue3GoogleLogin, {
    clientId: '80205897538-ohperlbu2i4080tv18u9ptp77e3es6d9.apps.googleusercontent.com'
}).use(store).use(router).mount('#app');