<template>
  <main class="main-container-bg">
    <div class="u-container mx-4 xl:mx-auto xl:w-9/12">
      <section class="rounded-3xl overflow-hidden">
        <header>
          <img src="@/assets/images/login-header.png" class="w-full" />
        </header>
        <article
          class="grid justify-center text-center gap-6 bg-white bg-opacity-90 p-6 pb-10"
        >
          <img
            src="@/assets/images/LOGO.png"
            alt="LOGO"
            class="mx-auto w-32 xl:w-auto xl:mb-4"
          />

          <h2 class="text-2xl xl:text-4xl font-bold text-main-brown">Regístrate</h2>
          <p class="xl:text-lg mb-6">
            Identifícate para obtener <br class="xl:hidden" />
            grandes beneficios<br class="hidden xl:block" />
            <!-- beneficios en <br class="xl:hidden" />
            <span class="font-bold xl:font-normal">Casablanca - Restobar</span> -->
          </p>
          <div class="grid xl:grid-cols-2 gap-4 mb-32 xl:mb-16">
            <google-component />
            <facebook-component />
          </div>
          <footer>
            <div class="flex items-center justify-center gap-6 mb-6">
              <a href="https://www.facebook.com/casablanca.rgb/" target="_Blank">
                <img src="@/assets/images/icon-facebook.png" alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/casablanca.rgb/" target="_Blank">
                <img src="@/assets/images/icon-instagram.png" alt="instagram" />
              </a>
              <a href="https://wa.link/l2bszb" target="_Blank">
                <img src="@/assets/images/icon-whatsapp.png" alt="whatsapp" />
              </a>
            </div>
            <p class="text-center">Vísítamos en nuestras redes</p>
          </footer>
        </article>
      </section>
    </div>
  </main>
</template>
<script>
import facebookComponent from "../Auth/Button/facebookComponent.vue";
import googleComponent from "../Auth/Button/googleComponent.vue";

export default {
  components: {
    facebookComponent,
    googleComponent,
  },
};
</script>
