<template>
  <a
    @click.prevent="handleClickSignIn"
    class="cursor-pointer text-[10px] sm:text-base flex items-center justify-center gap-4 py-4 border-2 border-gray-300 bg-white rounded-2xl"
  >
    <GoogleLogin id="test" :callback="callback" prompt auto-login />
    <!-- <img src="@/assets/images/google-button-icon.png" alt="Google" /> -->
    <!-- <span class="text-xs font-semibold">GOOGLE</span> -->
  </a>
</template>
<style>
#test span div div div {
  border: none;
  padding: 0;
  height: auto;
}
</style>
<script>
import { inject, toRefs } from "vue";
import { userService } from "@/service/user.service";
import { decodeCredential } from "vue3-google-login";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  mounted() {
    this.reWrite();
  },
  methods: {
    async callback(response) {
      // decodeCredential will retrive the JWT payload from the credential
      const userData = decodeCredential(response.credential);
      this.$store.state.auth.socialId = userData.sub;
      this.$store.state.auth.nameUser = userData.given_name;
      this.$store.state.auth.email = userData.email;
      this.$store.state.auth.redSocial = "google";
      const user = Object();
      user.social_id = userData.sub;
      user.origin = "google";
      user.email = userData.email;
      var dataUser = await userService.loginUser(user);
      if (dataUser.success === true) {
        if (dataUser.data === null) {
          this.$store.state.auth.estado = true;
        } else {
          const userToken =
            dataUser.data.origin === "google"
              ? dataUser.data.google_id
              : dataUser.data.facebook_id;
          const userSocial = dataUser.data.origin === "google" ? "g" : "f";
          //ALmacenamos en el State
          this.$store.state.user.social = userSocial;
          this.$store.state.user.socialId = userToken;

          this.$cookies.set("sessionUser", userSocial + "TRG25AVG" + userToken, 60 + 60);
          //Mandamos al usuario a su perfil
          this.$router.push({ name: "Perfil" });
          //ResetStorage
          this.$store.commit("setDataAuth");
        }
      }
      if (dataUser.success === false) {
        this.$store.commit("setDataAuth");
        if (dataUser.data.hasOwnProperty("email")) {
          var result = dataUser.data.email.find((element) => element === "existe");
          if (result) {
            alert("Inicie sesión con FACEBOOK");
          } else {
            alert("Hubo un error");
          }
        } else {
          alert("Error");
        }
      }
    },
    // async handleClickSignIn() {
    //   try {
    //     const googleUser = await this.$gAuth.signIn();
    //     if (!googleUser) {
    //       return null;
    //     }

    //   } catch (error) {
    //     return null;
    //   }
    // },

    // async handleClickGetAuthCode() {
    //   try {
    //     const authCode = await this.$gAuth.getAuthCode();
    //     console.log("authCode", authCode);
    //   } catch (error) {
    //     //on fail do something
    //     console.error(error);
    //     return null;
    //   }
    // },

    // async handleClickSignOut() {
    //   try {
    //     await this.$gAuth.signOut();
    //     console.log("isAuthorized", this.Vue3GoogleOauth.isAuthorized);
    //     this.user = "";
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    handleClickDisconnect() {
      window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`;
    },
    // Reescribir texto de boton de google
    reWrite() {
      setTimeout(() => {
        const labelSibling = document.getElementById("button-label");
        const label = labelSibling.previousElementSibling;

        label.textContent = "GOOGLE";
        label.classList.add("text-xs", "font-semibold");
      }, 200);
    },
  },
  setup(props) {
    const { isSignIn } = toRefs(props);
    const Vue3GoogleOauth = inject("Vue3GoogleOauth");
    const handleClickLogin = () => {};
    return {
      Vue3GoogleOauth,
      handleClickLogin,
      isSignIn,
    };
  },
};
</script>
