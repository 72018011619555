<template>
  <a
    @click.prevent="authFb"
    class="cursor-pointer text-[10px] sm:text-base flex items-center justify-center gap-4 py-4 border-2 border-[#1976D2] bg-[#1976D2] text-white rounded-2xl"
  >
    <img src="@/assets/images/facebook-button-icon.png" alt="Facebook" />
    <span class="text-xs font-semibold">FACEBOOK</span>
  </a>
</template>
<script>
import { userService } from "@/service/user.service";

export default {
  components: {},
  data() {
    return {};
  },

  methods: {
    async test() {
      this.$store.state.auth.socialId = "123456789";
      this.$store.state.auth.nameUser = "Marco Antonio";
      this.$store.state.auth.email = "marco.sotomayor@tecsup.edu.pe";
      this.$store.state.auth.redSocial = "facebook";
      const user = Object();
      user.social_id = "123456789";
      user.origin = "facebook";
      var dataUser = await userService.loginUser(user);
      if (dataUser.success === true) {
        if (dataUser.data === null) {
          this.$store.state.auth.estado = true;
        } else {
          const userToken =
            dataUser.data.origin === "facebook"
              ? dataUser.data.facebook_id
              : dataUser.data.google_id;
          const userSocial = dataUser.data.origin === "facebook" ? "f" : "g";
          //ALmacenamos en el State
          this.$store.state.user.social = userSocial;
          this.$store.state.user.socialId = userToken;
          //Creamos la cookie para la sesión
          this.$cookies.set("sessionUser", userSocial + "TRG25AVG" + userToken, 60 + 60);
          //Mandamos al usuario a su perfil
          this.$router.push({ name: "Perfil" });
          //ResetStorage
          this.$store.commit("setDataAuth");
        }
      }
      if (dataUser.success === false) {
        this.$store.commit("setDataAuth");
        alert("Error");
      }
    },
    authFb() {
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            // connected
            window.FB.api("/me", { fields: "name, email,picture" }, async (response) => {
              if (response) {
                this.$store.state.auth.socialId = response.id;
                this.$store.state.auth.nameUser = response.name;
                this.$store.state.auth.email = response.email;
                this.$store.state.auth.redSocial = "facebook";
                const user = Object();
                user.social_id = response.id;
                user.origin = "facebook";
                user.email = response.email;
                var dataUser = await userService.loginUser(user);
                if (dataUser.success === true) {
                  if (dataUser.data === null) {
                    this.$store.state.auth.estado = true;
                  } else {
                    const userToken =
                      dataUser.data.origin === "facebook"
                        ? dataUser.data.facebook_id
                        : dataUser.data.google_id;
                    const userSocial = dataUser.data.origin === "facebook" ? "f" : "g";
                    //ALmacenamos en el State
                    this.$store.state.user.social = userSocial;
                    this.$store.state.user.socialId = userToken;
                    //Creamos la cookie para la sesión
                    this.$cookies.set(
                      "sessionUser",
                      userSocial + "TRG25AVG" + userToken,
                      60
                    );
                    //Mandamos al usuario a su perfil
                    this.$router.push({ name: "Perfil" });
                    //ResetStorage
                    this.$store.commit("setDataAuth");
                  }
                }
                if (dataUser.success === false) {
                  this.$store.commit("setDataAuth");
                  if (dataUser.data.hasOwnProperty("email")) {
                    var result = dataUser.data.email.find(
                      (element) => element === "existe"
                    );
                    if (result) {
                      alert("Inicie sesión con GOOGLE");
                    } else {
                      alert("Hubo un error");
                    }
                  } else {
                    alert("Error");
                  }
                }
              }
            });
          }
        },
        { scope: "public_profile,email" }
      );
    },
  },
  computed: {},
};
</script>
