<template>
  <main class="main-container-bg">
    <div class="u-container mx-4 xl:mx-0 xl:w-9/12">
      <section class="rounded-3xl overflow-hidden">
        <header class="relative py-6">
          <img
            src="@/assets/images/login-header.png"
            class="absolute inset-0 w-full h-full"
          />
          <div class="bg-black bg-opacity-80 absolute inset-0 z-10"></div>

          <div
            class="flex items-center justify-center xl:justify-between text-white relative z-20 xl:px-16"
          >
            <div class="flex items-center justify-center gap-2 xl:gap-6">
              <h1 class="font-berkshire text-4xl xl:text-7xl">Chelómetro</h1>
              <img src="@/assets/images/chelometro-icon.png" class="w-20 xl:w-auto" />
            </div>
            <button
              @click.prevent="logout"
              class="hidden xl:block py-3 xl:py-4 px-12 rounded-full text-white text-xl bg-main-red font-bold"
            >
              Cerrar Sesión
            </button>
          </div>
        </header>
        <article
          class="flex flex-col xl:flex-row items-center justify-between gap-10 xl:gap-6 bg-white px-8 py-10 xl:p-24"
        >
          <div class="order-2 xl:order-1 mb-16 xl:mb-0">
            <p
              class="text-xl xl:text-3xl mb-10 xl:mb-6 flex items-center justify-center xl:justify-start gap-4"
            >
              <span>
                Hola<span class="font-bold text-main-brown">, {{ user.name }}</span>
              </span>
              <router-link
                v-if="user.name !== 'usuario'"
                :to="{
                  name: 'Edit',
                  params: {
                    name: user.name,
                  },
                }"
                class="text-main-red underline text-sm xl:text-xl font-bold"
                >Editar</router-link
              >
            </p>
            <p class="flex items-center gap-6 text-sm xl:text-xl font-bold mb-6">
              <span class="text-main-brown">Email:</span>
              <span>{{ user.email }}</span>
              <img
                v-if="user.origin === 'google'"
                src="@/assets/images/credenciales-google.svg"
                class="w-8"
              />
              <img v-else src="@/assets/images/credenciales-facebook.svg" class="w-8" />
            </p>
            <p class="flex items-center gap-6 text-sm xl:text-xl font-bold mb-6">
              <span class="text-main-brown">Celular:</span>
              <span>{{ user.celular }}</span>
            </p>
            <p class="flex items-center gap-6 text-sm xl:text-xl font-bold mb-6">
              <span class="text-main-brown">Documento:</span>
              <span>{{ user.dni }}</span>
            </p>
            <p class="flex items-center gap-6 text-sm xl:text-xl font-bold">
              <span class="text-main-brown">Fecha de cumpleaños:</span>
              <span>{{ user.birthday }}</span>
            </p>
            <div class="h-px w-full bg-main-brown bg-opacity-20 my-10"></div>
            <div class="flex items-center mb-10">
              <p class="text-sm xl:text-lg font-bold mr-4 xl:mr-10 text-main-brown">
                Chelas acumuladas:
              </p>
              <span
                class="py-2 px-10 xl:py-4 xl:px-16 border shadow-sm rounded-full text-sm xl:text-lg"
                >{{ user.totalChelas }}</span
              >
              <div class="ml-6 cursor-pointer tooltip hidden xl:block">
                <img src="@/assets/images/icon-question.png" alt="" />
                <span class="tooltip-box text-center text-white">
                  <b>RECUERDA:</b> <br />
                  Cada <b>chela compartida</b> deber ser reclamada <br />
                  para que puedas obtener el beneficio de sumar <br />
                  una chela para ti
                </span>
              </div>
            </div>
            <div class="flex items-center gap-6 xl:gap-14">
              <p class="text-sm xl:text-lg font-bold text-main-brown">
                Comparte tu link:
              </p>
              <a
                @click.prevent="copyLink"
                class="flex items-center gap-1 underline text-main-blue text-sm xl:text-lg ml-1 xl:ml-0 cursor-pointer"
              >
                Copiar mi link
                <img src="@/assets/images/url-icon.png" class="w-4 xl:w-6" />
              </a>
            </div>
            <button
              @click.prevent="logout"
              class="block xl:hidden py-3 xl:py-4 px-12 rounded-full text-white bg-main-red font-bold mt-10 mx-auto"
            >
              Cerrar Sesión
            </button>
          </div>
          <div class="order-1 xl:order-2">
            <!-- <div class="p-4 xl:p-8 bg-black mb-4"></div> -->
            <div class="p-4 xl:p-8 mb-4">
              <QRCodeVue3
                :value="user.link"
                :width="200"
                :height="200"
                :qrOptions="{ typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' }"
                :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
                :dotsOptions="{ type: 'square', color: '#000000' }"
                :cornersSquareOptions="{ type: 'square', color: '#000000' }"
              />
              <!-- <img src="@/assets/images/qr.png" class="mx-auto w-32 xl:w-auto" /> -->
            </div>
            <p class="text-lg text-center">
              Comparte tu QR <br />
              Y Gana Chelas <span class="text-main-red font-bold">GRATIS</span>
            </p>
          </div>
        </article>
      </section>
    </div>
  </main>
</template>

<script>
import { userService } from "@/service/user.service";
import { server } from "@/config/global";
import QRCodeVue3 from "qrcode-vue3";
export default {
  components: { QRCodeVue3 },
  data() {
    return {
      user: {
        name: "usuario",
        dni: "",
        birthday: "",
        totalChelas: 0,
        link: "",
        email: "",
        celular: "",
        origin: "",
      },
    };
  },
  created() {
    if (
      this.$store.state.user.social === null ||
      this.$store.state.user.socialId === null
    ) {
      this.getDataCoookiesUser();
    } else {
      this.getDataUser();
    }
  },
  mounted() {},
  methods: {
    getDataCoookiesUser() {
      const existCookie = this.$cookies.isKey("sessionUser");
      if (existCookie) {
        const cookieAdmin = this.$cookies.get("sessionUser");
        if (cookieAdmin === null) {
          this.$store.commit("setDataAuth");
          this.$store.commit("setDataUser");
          this.$cookies.remove("sessionUser");
          this.$router.push({ name: "Auth" });
        } else {
          try {
            const separatorData = cookieAdmin.split("TRG25AVG");
            this.$store.state.user.social = separatorData[0];
            this.$store.state.user.socialId = separatorData[1];
            this.getDataUser();
          } catch (e) {
            this.$store.commit("setDataAuth");
            this.$store.commit("setDataUser");
            this.$cookies.remove("sessionUser");
            this.$router.push({ name: "Auth" });
          }
        }
      } else {
        this.$store.commit("setDataAuth");
        this.$store.commit("setDataUser");
        this.$router.push({ name: "Auth" });
      }
    },

    async getDataUser() {
      var user = new Object();
      user.social = this.$store.state.user.social;
      user.socialId = this.$store.state.user.socialId;
      var dataUser = await userService.getDataUser(user);
      if (dataUser.success === true) {
        this.user.name = dataUser.data.nickname;
        this.user.dni = dataUser.data.dni;
        this.user.birthday = dataUser.data.birthday;
        this.user.totalChelas = dataUser.data.contador_chela;
        this.user.link = server.API_URL_AFILIADO + "?code=" + dataUser.data.code;
        this.user.email = dataUser.data.email;
        this.user.celular = dataUser.data.celular;
        this.user.origin = dataUser.data.origin;
      }
      if (dataUser.success === false) {
        alert("Hubo un error");
        this.$cookies.remove("sessionUser");
        this.$store.commit("setDataAuth");
        this.$store.commit("setDataUser");
        this.$router.push({ name: "Auth" });
      }
    },
    copyLink() {
      navigator.clipboard
        .writeText(this.user.link)
        .then(() => {
          alert("Link de afiliado copiado");
        })
        .catch((error) => console.log(error));
    },
    logout() {
      //ResetStorage
      this.$store.commit("setDataAuth");
      this.$store.commit("setDataUser");
      //ResetStorage
      this.$cookies.remove("sessionUser");
      this.$router.push({ name: "Auth" });
    },
  },
};
</script>
