<template>
  <autentification-component v-if="estadoAuth === false" />
  <registro-component v-if="estadoAuth === true" />
</template>

<script>
import autentificationComponent from "../components/Auth/autentificationComponent.vue";
import registroComponent from "../components/Auth/registroComponent.vue";

export default {
  components: {
    autentificationComponent,
    registroComponent,
  },
  computed: {
    estadoAuth() {
      return this.$store.state.auth.estado;
    },
  },
};
</script>

