import axios from 'axios'
import { server } from "../config/global"
export const userService = {
    updaNameUser(data) {
        let response
        let url = server.API_URL + 'update/user'
        response = axios
            .post(url, data)
            .then(response => {
                return { success: true, data: response.data.data }
            })
            .catch(errors => {
                return { success: false }
            })
        return response
    },
    loginUser(data) {
        let response
        let url = server.API_URL + 'authentication/user/login'
        response = axios
            .post(url, data)
            .then(response => {
                return { success: true, data: response.data.data }
            })
            .catch(errors => {
                return { success: false, data: errors.response.data.data }
            })
        return response
    },
    getDataUser(data) {
        let response
        let url = server.API_URL + 'user/data'
        response = axios
            .post(url, data)
            .then(response => {
                return { success: true, data: response.data.data }
            })
            .catch(errors => {
                return { success: false }
            })
        return response
    },
    registerUser(data, code) {

        let response
        var url
        if (code === undefined) {
            url = server.API_URL + 'authentication/user/register'
        } else {
            url = server.API_URL + 'authentication/user/register/' + code
        }

        response = axios
            .post(url, data)
            .then(response => {
                return { success: true, data: response.data.data }
            })
            .catch(errors => {
                return { success: false, data: errors.response.data.data }
            })
        return response
    },
}