<template>
  <main class="main-container-bg">
    <div class="u-container mx-4 xl:mx-0 xl:w-9/12">
      <section class="xl:grid xl:grid-cols-12 rounded-3xl overflow-hidden">
        <aside class="xl:col-span-5">
          <img src="@/assets/images/form-aside.png" class="hidden xl:block" />
          <img src="@/assets/images/login-header.png" class="xl:hidden" />
        </aside>
        <article
          class="bg-white bg-opacity-90 xl:col-span-7 pb-16 xl:py-16 px-6 xl:px-10 grid items-center"
        >
          <img
            src="@/assets/images/chelometro-icon.png"
            class="xl:hidden w-52 mx-auto mb-6 -mt-8"
          />
          <h2 class="text-center text-xl xl:text-3xl">Rellena tus datos y</h2>
          <h2 class="text-center text-2xl xl:text-4xl font-bold text-main-borwn mb-10">
            obtén <span class="text-main-red">GRATIS</span> una chela
          </h2>
          <form @submit.prevent="registerUser()" class="grid gap-5">
            <label>
              <span class="text-sm xl:text-lg font-bold mb-3 block"
                >DNI/Pasaporte/Documento</span
              >
              <input
                v-model="userData.dni"
                type="text"
                class="outline-none focus:ring focus:ring-main-red transition-colors py-4 px-4 xl:px-8 rounded-full w-full text-sm xl:text-lg"
                placeholder="DNI/Pasaporte/Documento"
                required
              />
            </label>
            <label>
              <span class="text-sm xl:text-lg font-bold mb-3 block">Teléfono</span>
              <input
                v-model="userData.celular"
                type="text"
                class="outline-none focus:ring focus:ring-main-red transition-colors py-4 px-4 xl:px-8 rounded-full w-full text-sm xl:text-lg"
                placeholder="987654321"
                required
              />
            </label>
            <label>
              <span class="text-sm xl:text-lg font-bold mb-3 block"
                >Fecha de cumpleaños</span
              >
              <input
                v-model="userData.birthday"
                type="date"
                class="outline-none focus:ring focus:ring-main-red transition-colors py-4 px-4 xl:px-8 rounded-full w-full text-sm xl:text-lg"
                placeholder="DD/MM/AAAA"
                required
              />
            </label>
            <button
              type="submit"
              class="py-3 xl:py-4 px-12 rounded-full text-white bg-main-red text-lg font-bold mt-4 mx-auto w-min"
            >
              Enviar
            </button>
          </form>
        </article>
      </section>
    </div>
  </main>
</template>
<script>
import { userService } from "../../service/user.service";
export default {
  data() {
    return {
      userData: {
        dni: "",
        celular: "",
        birthday: "",
        code_invitation: this.$route.query.code,
      },
    };
  },
  methods: {
    setUserData() {
      this.userData.dni = "";
      this.userData.celular = "";
      this.userData.birthday = "";
    },
    async registerUser() {
      var user = new Object();
      user.nickname = this.$store.state.auth.nameUser;
      user.email = this.$store.state.auth.email;
      user.dni = this.userData.dni;
      user.origin = this.$store.state.auth.redSocial;
      user.celular = this.userData.celular;
      user.birthday = this.userData.birthday;
      if (this.$store.state.auth.redSocial === "google") {
        user.google_id = this.$store.state.auth.socialId;
      }
      if (this.$store.state.auth.redSocial === "facebook") {
        user.facebook_id = this.$store.state.auth.socialId;
      }
      var dataUser = await userService.registerUser(user, this.userData.code_invitation);

      if (dataUser.success === true) {
        //ResetStorage
        this.$store.commit("setDataAuth");
        //ResetStorage
        const userToken =
          dataUser.data.origin === "facebook"
            ? dataUser.data.facebook_id
            : dataUser.data.google_id;
        const userSocial = dataUser.data.origin === "facebook" ? "f" : "g";
        //ALmacenamos en el State
        this.$store.state.user.social = userSocial;
        this.$store.state.user.socialId = userToken;
        //Creamos la cookie para la sesión
        this.$cookies.set("sessionUser", userSocial + "TRG25AVG" + userToken, 60 + 60);
        //Mandamos al usuario a su perfil
        this.$router.push({ name: "Perfil" });
      }
      if (dataUser.success === false) {
        this.setUserData();
        this.$store.commit("setDataAuth");
        if (dataUser.data.hasOwnProperty("dni")) {
          alert(dataUser.data.dni[0]);
        } else {
          alert("Hubo un error");
        }
      }
    },
  },
};
</script>
