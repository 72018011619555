<template>
  <main class="main-container-bg">
    <div class="u-container mx-4 xl:mx-auto xl:w-9/12">
      <section class="rounded-3xl overflow-hidden relative">
        <article class="text-center bg-white bg-opacity-90 px-6 xl:px-32 py-28">
          <p class="xl:text-xl italic mb-4 xl:mb-10">
            “Recuerda que el <b>nombre y apellido</b> debe ser el mismo que figura en tu
            <br />
            <b>documento de identidad”</b>
          </p>
          <div class="h-px w-full bg-gray-400 mb-20"></div>
          <h3
            class="text-xl xl:text-3xl text-main-brown font-bold border-b border-gray-400 w-max mx-auto px-8 pb-1 mb-10"
          >
            “{{ userName }}”
          </h3>
          <form class="w-full xl:w-1/2 mx-auto mb-20">
            <label>
              <input
                v-model="userName"
                class="block mb-14 outline-none focus:ring focus:ring-main-red transition-colors py-4 px-4 xl:px-8 rounded-full w-full text-sm xl:text-lg"
                placeholder="Primer Nombre y Primer Apellido "
              />
            </label>
            <button
              @click.prevent="updateName"
              class="py-3 xl:py-4 px-12 rounded-full text-white bg-main-red xl:text-lg font-bold"
            >
              Cambiar mis nombres
            </button>
          </form>
        </article>
        <router-link
          :to="{ name: 'Perfil' }"
          class="absolute top-6 right-6"
          type="button"
        >
          <img src="@/assets/images/icon-exit.png" class="w-10 xl:w-auto" />
        </router-link>
      </section>
    </div>
  </main>
</template>
<script>
import { userService } from "@/service/user.service";
export default {
  data() {
    return {
      userName: this.$route.params.name,
    };
  },
  methods: {
    async updateName() {
      var user = new Object();
      user.social_id = this.$store.state.user.socialId;
      user.origin = this.$store.state.user.social;
      user.name = this.userName;
      
      var dataUser = await userService.updaNameUser(user);
      if (dataUser.success === true) {
        this.$router.push({ name: "Perfil" });
      }
      if (dataUser.success === false) {
        alert("Hubo un error");
        this.$store.commit("setDataAuth");
        this.$store.commit("setDataUser");
        this.$cookies.remove("sessionUser");
      }
    },
  },
};
</script>
