import { createStore } from 'vuex'
import { useCookies } from "vue3-cookies";
import router from "@/router"

const { cookies } = useCookies();
export default createStore({
    state: {
        auth: {
            redSocial: null,
            socialId: null,
            nameUser: null,
            email: null,
            estado: false,
        },

        user: {
            social: null,
            socialId: null,
        }
    },
    mutations: {
        setDataUser(state) {
            state.user.social = null;
            state.user.socialId = null;
        },
        setDataAuth(state) {
            state.auth.redSocial = null;
            state.auth.socialId = null;
            state.auth.nameUser = null;
            state.auth.email = null;
            state.auth.estado = false;
        }
    },
    actions: {},
    modules: {}
})